<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Colaboradores</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <ButtonAddNew route="cadastro-colaborador" />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :search="search"
                            :height="$vuetify.breakpoint.height - 170"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                        >
                            <template v-slot:item.admin="{ item }">
                                {{ item.admin ? 'Sim' : 'Não' }}
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon @click.stop="excluir(item.id)" color="red">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';

export default {
    name: 'Colaborador',

    components: {
        BaseContainer,
        ButtonAddNew,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        headers: [
            { text: 'Código', value: 'id' },
            { text: 'Nome', value: 'name' },
            { text: 'E-mail', value: 'email' },
            { text: 'Admin', value: 'admin' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];
            this.$http.get('usuarios').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
        },

        editar(row) {
            this.$store.commit('setData', row);
            this.$router.push('/cadastro-colaborador');
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.delete(`usuarios/${id}`).then(resp => {
                    const data = resp.data;
                    if (data.type == 'warning') {
                        this.notify(data.msg, 'warning');
                        return;
                    }
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                })
                .catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                });
            }
        },
    },
}
</script>
